export const DeviceDetailHierarchy = [
  {
    label: 'Operation Manager',
    isOpen: true,
    children: [
      {
        label: 'Select Operation',
        key: 'Task',
        type: 'select',
        description: [
          `Task changes are only allowed in 'Idle' mode; please switch to 'Idle' before changing tasks.`,
        ],
      },
      {
        label: 'Select Mode',
        key: 'mode',
        type: 'dropdown',
        description: [
          `Idle: Puts the device into a mode where it does not perform any tasks`,
          `Recording: Initiates the device's function to capture and store the training dataset`,
          `Guidance: Initiates the operation on the device to provide guidance without recording trace videos`,
          `Guidance + Trace: Initiates the operation on the device to provide guidance while simultaneously recording trace videos.`,
          `Note: Transitions between 'Recording' and 'Guidance' modes require switching to 'Idle' mode first.`,
        ],
      },
    ],
  },
  {
    label: 'Administrative Tools',
    isOpen: true,
    children: [
      {
        label: 'Manage Online',
        key: 'manage',
        description: [
          'Enabling this allows users to manage stations directly from the portal.',
        ],
      },
      {
        label: 'Enable Auto Updates',
        key: 'auto_update_enabled',
        description: [
          'Enabling this allows the software to automatically download and install updates.',
        ],
      },
      {
        label: 'Enable Translation',
        key: 'is_translation_enabled',
        description: [
          'Enabling this allows users to see translations in their native language. Note: Users must enter translations for each step first.',
        ],
      },
    ],
  },
  {
    label: 'Advanced Guidance Settings',
    isOpen: true,
    children: [
      {
        label: 'Blur Face',
        key: 'blurFace',
        description: [
          'Enabling this allows users to blur faces during active guidance to ensure worker privacy.',
        ],
      },
      {
        label: 'AI Visualization',
        key: 'box_visualization',
        description: [
          'Enabling this allows users to see the guide on their screen for better assistance.',
        ],
      },
      {
        label: 'Projector',
        key: 'projector_enable',
        description: [
          'Enabling this enhances guidance by projecting real-time instructions in text, shapes, images, or GIFs directly onto the workspace.',
        ],
      },
    ],
  },
  {
    label: 'Interactive Guidance Tools',
    isOpen: true,
    children: [
      // {
      //     label: 'Previous Cycle Error',
      //     key: 'show_previous_cycle_errors',
      //     description: 'Enabling this allows users to visualize errors from their previous cycle.'
      // },
      {
        label: 'Enable Camera',
        key: 'enable_video',
        description: [
          "Enabling this allows users to open and use the device's camera.",
        ],
      },
      {
        label: 'Enable Step Illustrations',
        key: 'show_step_images',
        description: [
          'Enabling this allows users to receive guidance through video steps.',
        ],
      },
      {
        label: 'Show/Hide Task Steps',
        key: 'show_task_definition',
        description: [
          'Enabling this allows users to view detailed work instructions.',
        ],
      },
    ],
  },
];
