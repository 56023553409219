<template>
  <div class="w-90 py-2 my-5" style="margin-bottom: 30px">
    <a-row
      style="height: fit-content; border: 1px solid grey"
      class="rounded-full d-flex justify-content-center"
    >
      <a-col v-for="item in DeviceDetail" :key="item" :span="24" class="">
        <div
          style="background-color: #f6f6f6; gap: 5px"
          class="d-flex p-3 justify-content-start align-items-center"
        >
          <div @click="() => tabsHandler(item.label)">
            <UpOutlined v-if="!item.isOpen" class="cursor-pointer" />
            <DownOutlined v-else class="cursor-pointer" />
          </div>
          <div style="font-weight: 600; font-size: 14px" class="">
            {{ item.label }}
          </div>
        </div>
        <span v-if="item.isOpen">
          <hr style="margin: 0" />
          <span
            v-for="subItem in item.children"
            :key="subItem.label"
            style="background-color: #ffffff; font-weight: 400; font-size: 16px"
            class="px-3"
          >
            <span
              v-if="subItem?.type == 'select'"
              class="deviceDetailCard flex justify-content-between align-items-center py-2 w-60"
            >
              <span>
                {{ subItem.label }}
              </span>
              <a-select
                v-model:value="deviceState[subItem.key]"
                placeholder="Select operation"
                style="width: 240px"
                :loading="isLoading || isTaskUpdating"
                show-search
                :options="taskOptions"
                :filter-option="filterOption"
                :disabled="isControlsDisabled || isTaskDisabled"
              />
            </span>
            <span
              v-else-if="subItem?.type == 'dropdown'"
              class="deviceDetailCard flex justify-content-between align-items-center py-2 w-60"
            >
              <span>
                {{ subItem.label }}
              </span>
              <a-select
                v-model:value="selectedMode"
                placeholder="Select mode"
                style="width: 240px"
                :loading="isLoading || isTaskUpdating"
                :options="dropdownOptions"
                :disabled="isControlsDisabled"
                @change="(val) => handleModeDropdown(val)"
              />
            </span>
            <span
              v-else-if="subItem?.type == 'button'"
              class="deviceDetailCard flex justify-content-between align-items-center w-60"
            >
              <span>
                {{ subItem.label }}
              </span>
              <a-radio
                v-model:checked="checked"
                :disabled="handleDisabled(subItem.key)"
                @click="handleTaskClick(subItem.key)"
              ></a-radio>
            </span>
            <span
              v-else
              style="background-color: #ffffff"
              class="deviceDetailCard flex justify-content-between align-items-center w-60"
            >
              <span>
                {{ subItem.label }}
              </span>
              <a-switch
                v-model:checked="deviceState[subItem.key]"
                size="small"
                :disabled="handleDisabled(subItem.key)"
              />
            </span>

            <div
              v-if="subItem.key !== 'Task'"
              style="width: 100%; margin: 0 auto"
              class="text-sm italic labelDescriptionText"
            >
              <div v-for="desc in subItem.description" :key="desc">
                {{ desc }}
              </div>
            </div>
            <div v-else class="text-sm italic labelDescriptionText">
              <span v-if="taskModelVersion">
                Current model version:
                <span
                  class="cursor-pointer labelDescriptionLink"
                  @click="navigateToAnalytics"
                  >{{ taskModelVersion }}</span
                >
              </span>

              <div v-for="desc in subItem.description" :key="desc">
                {{ desc }}
              </div>
            </div>
          </span>
        </span>
      </a-col>
    </a-row>
    <div
      style="
        position: absolute;
        bottom: 0;
        right: 20px;
        background: white;
        width: 100%;
        padding: 15px;
      "
      class="d-flex justify-content-end align-items-center mt-5"
    >
      <a-button :disabled="disabledButton" @click="updateDevice">
        Apply to Device
      </a-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DeviceDetailHierarchy } from './options';
import { getSortedTask } from 'src/utils/task';
import TaskService from 'src/services/tasks';
import dateHelper from 'src/components/shared/Helpers/dateHelper';
import DeviceService from 'src/services/device.js';
import trainingStatuses from '@/config/training-status-config.js';
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue';
import types from 'src/store/mutation-types';

function getInitialDeviceState(hierarchy, initialState) {
  const deviceState = JSON.parse(JSON.stringify(initialState));

  function traverse(nodes) {
    nodes.forEach((node) => {
      if (node?.key) {
        deviceState[node.key] = initialState[node.key] || false;
      }
      if (node.children) {
        traverse(node.children);
      }
    });
  }

  traverse(hierarchy);
  return deviceState;
}

export default {
  name: 'DeviceDetailPage',
  components: {
    DownOutlined,
    UpOutlined,
  },
  inject: ['toast'],
  emits: ['updateListData'],
  setup() {
    return {
      DeviceDetailHierarchy,
    };
  },
  data() {
    // Ensure selectedTaskForDetail is available and contains initial values
    return {
      showStreamModal: false,
      editName: false,
      deviceTitle: '',
      trainedTasks: [],
      task_list: [],
      deviceState: undefined,
      DeviceDetail: DeviceDetailHierarchy,
      oldDeviceState: undefined,
      disabledButton: true,
      selectedMode: null,
      taskModelVersion: null,
    };
  },

  computed: {
    ...mapGetters(['selectedTaskForDetail', 'currentRoute']),
    currentRoute: {
      set(value) {
        this.$store.commit('changeRoute', value);
      },
    },
    isTaskDisabled() {
      return (
        this.selectedMode === 'guidance' ||
        this.selectedMode === 'recording' ||
        this.selectedMode === 'guidance+trace'
      );
    },
    isActive() {
      return !!this.selectedTaskForDetail.active;
    },
    isControlsDisabled() {
      return !this.isActive || !this.deviceState.manage;
    },
    taskOptions() {
      return getSortedTask(this.task_list);
    },
    dropdownOptions() {
      if (!this.oldDeviceState?.Task || !this.deviceState?.Task) {
        return;
      }
      // Find the selected task based on deviceState.Task
      let selectedTask = this.trainedTasks.find(
        (data) => data.task.id === this.deviceState.Task
      );
      // Determine if the last two options should be disabled
      const shouldDisable = !selectedTask;

      const isOldStateIdle = !(
        this?.oldDeviceState?.record_shift ||
        this?.oldDeviceState?.isInferenceRunning ||
        this?.oldDeviceState?.recordInference
      );
      return [
        { label: 'Idle', value: 'idle' },
        {
          label: 'Recording',
          value: 'recording',
          disabled:
            !isOldStateIdle ||
            this.oldDeviceState.Task !== this.deviceState.Task,
        },
        {
          label: 'Guidance',
          value: 'guidance',
          disabled:
            shouldDisable ||
            !isOldStateIdle ||
            this.oldDeviceState.Task !== this.deviceState.Task,
        }, // Conditionally disable
        {
          label: 'Guidance + Trace',
          value: 'guidance+trace',
          disabled:
            shouldDisable ||
            !isOldStateIdle ||
            this.oldDeviceState.Task !== this.deviceState.Task,
        },
      ];
    },
  },

  watch: {
    task_list: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.taskModelVersion = newVal.find(
            (data) => data.id === this.deviceState.Task
          )?.model_version;
        }
      },
      deep: true,
    },
    deviceState: {
      handler(newVal, oldValue) {
        // Check if there's a difference with the old state whenever DeviceDetail changes
        if (oldValue == undefined && newVal) {
          this.disabledButton =
            JSON.stringify(this.deviceState) === JSON.stringify(newVal);

          this.oldDeviceState = JSON.parse(JSON.stringify(newVal));
        } else {
          this.disabledButton =
            JSON.stringify(newVal) === JSON.stringify(this.oldDeviceState);
        }
      },
      deep: true, // Watch for nested changes
      immediate: true,
    },
    selectedTaskForDetail: {
      handler(newVal) {
        if (Object.keys(newVal).length == 0) {
          return;
        }
        // Update deviceState when selectedTaskForDetail changes
        this.deviceState = getInitialDeviceState(
          DeviceDetailHierarchy,
          newVal || {}
        );
        this.deviceTitle = newVal.title;
        this.disabledButton =
          JSON.stringify(newVal) === JSON.stringify(this.oldDeviceState);

        if (
          this?.deviceState?.isInferenceRunning &&
          this?.deviceState?.recordInference
        ) {
          this.selectedMode = 'guidance+trace';
        } else if (this?.deviceState?.isInferenceRunning) {
          this.selectedMode = 'guidance';
        } else if (this?.deviceState?.record_shift) {
          this.selectedMode = 'recording';
        } else if (
          !this?.deviceState?.isInferenceRunning &&
          !this?.deviceState?.recordInference &&
          !this?.deviceState?.record_shift
        ) {
          this.selectedMode = 'idle';
        }
      },
      immediate: true,
      deep: true,
    },
  },

  async created() {
    await this.getTaskList();
    await this.getTrainedTaskList();
  },

  methods: {
    ...mapActions(['showModal']),

    tabsHandler(label) {
      this.DeviceDetail = this.DeviceDetail.map((data) => {
        if (data.label == label) {
          data.isOpen = !data.isOpen;
          return data;
        }
        return data;
      });
    },
    navigateToAnalytics() {
      this.currentRoute = ['Define Operation'];

      this.$router.replace({
        name: 'Define Operation',
        query: {
          task: this.deviceState.Task,
        },
      });

      this.showModal({
        modalType: types.SHOW_MODEL_VERSIONS_MODAL,
        value: true,
      });
    },
    handleModeDropdown(value) {
      if (value == 'guidance+trace') {
        this.selectedMode = 'guidance+trace';

        this.deviceState.isInferenceRunning = true;
        this.deviceState.recordInference = true;
        this.deviceState.record_shift = false;
      } else if (value == 'guidance') {
        this.selectedMode = 'guidance';
        this.deviceState.recordInference = false;

        this.deviceState.record_shift = false;
        this.deviceState.isInferenceRunning = true;
      } else if (value == 'recording') {
        this.selectedMode = 'recording';
        this.deviceState.record_shift = true;

        this.deviceState.isInferenceRunning = false;
        this.deviceState.recordInference = false;
      } else if (value == 'idle') {
        this.deviceState.record_shift = false;
        this.deviceState.isInferenceRunning = false;
        this.deviceState.recordInference = false;

        this.selectedMode = 'idle';
      }
    },
    handleNavigator() {
      this.$router.push({ path: `/user/devices` });
    },
    async handleSaveDeviceName() {
      if (this.editName == false) {
        this.deviceTitle = this.deviceState.display_name;
        this.editName = true;
        return;
      }
      this.editName = false;
      const [error] = await DeviceService.updateDevice(
        this.deviceState.Serial_number,
        { ...this.deviceState, display_name: this.deviceTitle },
        false
      );
      if (error) {
        this.toast.error('Unable to update the name!');
      }
      this.deviceState.display_name = this.deviceTitle;
    },

    renderErrorToast(message) {
      const timeout = { timeout: 2000 };
      this.toast.info(message, timeout);
      return false;
    },

    isTaskChanged() {
      if (typeof this.oldDeviceState.Task == 'object') {
        return (
          this.selectedTaskForDetail?.Task?.id !== this.oldDeviceState.Task?.id
        );
      }
      if (typeof this.selectedTaskForDetail.Task == 'object') {
        return (
          this.selectedTaskForDetail.Task?.id !== this.oldDeviceState?.Task
        );
      }
      return this.deviceState?.Task !== this.oldDeviceState?.Task;
    },

    validateRecordShift() {
      const { record_shift, isInferenceRunning } = this.deviceState;
      // if ((record_shift && isInferenceRunning) || this.isTaskChanged()) {
      //   this.renderErrorToast(
      //     'You cannot start inference, manage shift or change task when recording shift.'
      //   );
      //   return false;
      // }
      // return true;
      if ((record_shift || isInferenceRunning) && this.isTaskChanged()) {
        this.renderErrorToast(
          'You cannot start inference, manage shift or change task when recording shift.'
        );
        return false;
      }

      return true;
    },

    isDeviceOn(deviceProp) {
      const oldSettings = this.oldDeviceState;
      const newSettings = this.deviceState;

      const oldS = oldSettings[deviceProp];
      const newS = newSettings[deviceProp];
      return oldS === true && newS === true;
    },

    isControlFlipped(deviceProp) {
      const oldSettings = this.oldDeviceState;
      const newSettings = this.deviceState;

      const oldS = oldSettings[deviceProp];
      const newS = newSettings[deviceProp];
      return !(oldS === newS);
    },

    isShiftTime() {
      if (
        !this.oldDeviceState.shift_start_time ||
        !this.oldDeviceState.shift_end_time
      ) {
        return false;
      }
      let prevStartShiftTime = new Date(
        this.oldDeviceState.shift_start_time.slice(0, -1)
      ).getTime();
      let prevEndShiftTime = new Date(
        this.oldDeviceState.shift_end_time.slice(0, -1)
      ).getTime();
      let currentTime = new Date().getTime();
      return prevStartShiftTime < currentTime && currentTime < prevEndShiftTime;
    },

    getDeviceStates() {
      return {
        is_shift_managed: this.isDeviceOn('is_shift_managed'),
        recordInferenceFlipped: this.isControlFlipped('recordInference'),
        isInferenceRunning: this.isDeviceOn('isInferenceRunning'),
        isShiftTime: this.isShiftTime(),
      };
    },

    validateRecordInference() {
      const {
        is_shift_managed,
        recordInferenceFlipped,
        isInferenceRunning,
        isShiftTime,
      } = this.getDeviceStates();

      if (is_shift_managed && recordInferenceFlipped && isShiftTime)
        return this.renderErrorToast(
          'Recording cannot be toggled while Manage Shift is enabled. Please disable Manage Shift first.'
        );

      if (
        recordInferenceFlipped &&
        isInferenceRunning &&
        !this.deviceState.is_shift_managed
      )
        return this.renderErrorToast(
          'Recording cannot be toggled while Inference is running. Please stop Inference first.'
        );

      return true;
    },

    setupUpdateDeviceSetting() {
      this.date = new Date();
      this.currentFormattedDate = dateHelper.getFormattedDate(this.date);
      if (!this.controlTime) this.controlTime = '00:00:00Z';
      let data = {
        blurFace: this.deviceState.blurFace,
        depth_cam_enabled: this.deviceState.depth_cam_enabled,
        isInferenceRunning: this.deviceState.isInferenceRunning,
        manage: this.deviceState.manage,
        recordInference: this.deviceState.recordInference,
        is_shift_managed: this.deviceState.is_shift_managed,
        record_shift: this.deviceState.record_shift,
        // shift_start_time: this.currentFormattedDate + 'T' + this.start_time,
        // shift_end_time: this.currentFormattedDate + 'T' + this.end_time,
        control_image_time: this.currentFormattedDate + 'T' + this.controlTime,
        mode: this.currentCameraMode,
        multi_view: this.deviceState.multi_view,
        is_translation_enabled: this.deviceState.is_translation_enabled,
        // analytics_start_time:
        //   this.currentFormattedDate + 'T' + this.analytics_start_time,
        // analytics_end_time:
        //   this.currentFormattedDate + 'T' + this.analytics_end_time,
        projector_enable: this.deviceState.projector_enable,
        remote_cam_enabled: this.deviceState.remote_cam_enabled,
        day_of_week_for_recording: this.deviceState.day_of_week_for_recording,
        box_visualization: this.deviceState.box_visualization,
        show_previous_cycle_errors: this.deviceState.show_previous_cycle_errors,
        enable_video: this.deviceState.enable_video,
        show_step_images: this.deviceState.show_step_images,
        show_task_definition: this.deviceState.show_task_definition,
        auto_update_enabled: this.deviceState.auto_update_enabled,
        upload_bad_cycle_videos: this.deviceState.upload_bad_cycle_videos,
        fps_cap: this.deviceState.fps_cap,
      };

      data = Object.entries(data)
        .filter(([key, val]) => this.oldDeviceState[key] !== val)
        .reduce((res, el) => {
          res[el[0]] = el[1];
          return res;
        }, {});
      if (this.isTaskChanged()) {
        data['Task'] = this.deviceState.Task;
        if (this.isTrainedTask(this.deviceState.Task))
          data['isInferenceRunning'] = false;
        this.isTaskUpdating = true;
      }

      return JSON.stringify(data);
    },

    SendDeviceUpdate(requestPayload) {
      return new Promise(async (resolve) => {
        const [error, data] = await DeviceService.updateDeviceSettings(
          this.oldDeviceState.Organization,
          this.oldDeviceState.id,
          requestPayload,
          false
        );
        if (error) this.renderErrorToast('Failed to update device settings');
        else
          this.toast.success(
            'We are applying your changes. This should take a minute.'
          );
        resolve(data || {});
      });
    },

    async updateDevice() {
      this.isApplyingChanges = true;
      if (!this.validateRecordInference()) {
        this.isApplyingChanges = false;
        return;
      }

      if (!this.validateRecordShift()) {
        this.isApplyingChanges = false;
        return;
      }

      const requestPayload = this.setupUpdateDeviceSetting();

      try {
        const updatedDeviceSetting = await this.SendDeviceUpdate(
          requestPayload
        );
        updatedDeviceSetting['Task'] = this.deviceState?.Task;
        // updatedDeviceSetting['Task'] = updatedDeviceSetting?.Task?.id;
        this.deviceState = { ...this.deviceState, ...updatedDeviceSetting };
        const deviceInfo = updatedDeviceSetting.Device;
        delete updatedDeviceSetting.Device;
        this.oldDeviceState = { ...this.deviceState };
        this.$emit('updateListData', {
          ...deviceInfo,
          ...updatedDeviceSetting,
          type: true,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isTaskUpdating = false;
        this.isApplyingChanges = false;
      }
    },

    filterOption(input, option) {
      return option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },

    getTaskList() {
      return new Promise(async (resolve) => {
        const [error, data] = await TaskService.fetchTasks(false);
        if (error) {
          console.log(error);
          return resolve();
        }
        this.task_list = data;
        this.taskIdToTaskNameMap = data.reduce((res, task) => {
          res[task.id] = task.taskName;
          return res;
        }, {});
        resolve();
      });
    },

    getTrainedTaskList() {
      return new Promise(async (resolve) => {
        const [error, data] = await TaskService.fetchTrainedTasksByStatus(
          trainingStatuses.trained,
          false
        );
        if (error) {
          console.log(error);
          return resolve();
        }
        this.trainedTasks = data;
        resolve();
      });
    },

    isTrainedTask(taskId = this.deviceState.Task) {
      return !this.trainedTasks.map(({ task }) => task.id).includes(taskId);
    },

    handleTaskClick(key) {
      if (key == 'startTask') {
        this.startInference();
      } else {
        this.stopInference();
      }
    },

    startInference() {
      if (this.isTrainedTask()) {
        this.toast.info('Cannot run inference on Untrained task!');
        return;
      }
      this.deviceState.isInferenceRunning = true;
      this.deviceState.recordInference = true;
    },

    stopInference() {
      this.deviceState.isInferenceRunning = false;
      this.deviceState.recordInference = false;
      this.deviceState.show_step_images = false;
    },

    handleDisabled(key) {
      if (!this.oldDeviceState) return false;
      if (key == 'manage') {
        return !this.isActive;
      } else if (key == 'record_shift') {
        return (
          this.isControlsDisabled ||
          this.oldDeviceState.isInferenceRunning ||
          this.oldDeviceState.recordInference
        );
      } else if (key == 'recordInference') {
        return (
          this.isControlsDisabled ||
          this.oldDeviceState.record_shift ||
          !this.oldDeviceState.isInferenceRunning
        );
      } else if (key == 'blurFace') {
        return this.isControlsDisabled;
      } else if (key == 'is_translation_enabled') {
        return this.isControlsDisabled;
      } else if (key == 'box_visualization') {
        return this.isControlsDisabled;
      } else if (key == 'show_previous_cycle_errors') {
        return this.isControlsDisabled;
      } else if (key == 'enable_video') {
        return this.isControlsDisabled;
      } else if (key == 'show_step_images') {
        return (
          this.isControlsDisabled ||
          this.oldDeviceState.record_shift ||
          !this.oldDeviceState.isInferenceRunning
        );
      } else if (key == 'show_task_definition') {
        return this.isControlsDisabled;
      } else if (key == 'auto_update_enabled') {
        return this.isControlsDisabled;
      } else if (key == 'depth_cam_enabled') {
        return this.isControlsDisabled;
      } else if (key == 'multi_view') {
        return (
          this.isControlsDisabled ||
          this.oldDeviceState.recordInference ||
          this.oldDeviceState.record_shift
        );
      } else if (key == 'remote_cam_enabled') {
        return (
          this.isControlsDisabled ||
          this.oldDeviceState.recordInference ||
          this.oldDeviceState.record_shift
        );
      } else if (key == 'startTask') {
        return (
          this.isControlsDisabled ||
          this.oldDeviceState.isInferenceRunning ||
          this.oldDeviceState.record_shift
        );
      } else if (key == 'stopTask') {
        return (
          this.isControlsDisabled || !this.oldDeviceState.isInferenceRunning
        );
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.deviceDetailPageBreadCrumb {
  font-weight: 600;
  font-size: 20px;
}

/* card style  %;
    background-color: '#f0f0f0'"*/
.deviceDetailCard {
  width: 100%;
  padding: 0 15px;
  background-color: '#ffffff' !important;
  font-weight: 400;
}

.rounded-full {
  border-radius: 10px;
  overflow: hidden;
}
.breadCrumbLink {
  cursor: pointer;
  color: black;
}

.deviceDetailTitle {
  font-weight: 500;
  font-size: 18px;
}

.detailPageTitleComponent {
  padding: 2px;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: fit-content;
}

.detailPageInoutComponent {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 2px;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 250px;
}

.detailPageInoutComponent .detailPageInout {
  border: none;
  flex-grow: 1;
  outline: none;
}

.iconContainer {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #4bb543;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-sm {
  font-size: 12px;
  color: gray;
}
.italic {
  font-style: italic;
}
.labelDescriptionText {
  margin-top: 5px !important;
  padding: 0 15px;
}
.labelDescriptionLink {
  font-weight: 600;
  cursor: pointer;
  color: rgb(100, 164, 241);
}

.labelDescriptionLink:hover {
  text-decoration: underline;
}
</style>
